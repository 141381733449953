
export default {
  name: 'MediWidgetButton',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
}
