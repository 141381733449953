import findCities from '~/graphql/products/queries/findCities.graphql'

export default {

  methods: {
    async findCities (currentPage = 1, urlKey, searchValue, vendorSlug, citySlug, serviceAtHome) {
      let cities
      let pageInfo

      const cityFilters = vendorSlug
        ? {
            ...(serviceAtHome && { service_at_home: { eq: serviceAtHome } }),
            product_url_key: { in: [urlKey] },
            ...(searchValue && { city_name_starts_with: searchValue }),
            ...(vendorSlug && { vendor_slug: { eq: vendorSlug } }),
            ...(citySlug && { city_slug: { in: citySlug } }),
          }
        : {
            product_url_key: { in: [urlKey] },
            ...(serviceAtHome && { service_at_home: { eq: serviceAtHome } }),
            ...(searchValue && { city_name_starts_with: searchValue }),
            ...(citySlug && { city_slug: { in: citySlug } }),
          }

      await this.$graphql({
        requestPayload: {
          query: findCities,
          variables: {
            cityFilters,
            cityPageSize: 150,
            cityCurrentPage: currentPage,
            citySort: {
              name: 'ASC',
            },
            ...(citySlug && { firstBySlug: citySlug }),
          },
          useGETForQueries: true,
          context: {
            fetchOptions: {
              method: 'GET',
            },
          },
        },
        successHandler: ({ data }) => {
          cities = data.findCities?.items
          pageInfo = data.findCities?.pageInfo
        },
        errorHandler: ({ message }) => {
          // eslint-disable-next-line no-console
          console.log(message)
        },
      })

      return {
        cities,
        pageInfo,
      }
    },
  },
}
