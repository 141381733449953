
import cmsBlock from '~/mixins/cmsBlock'
import {
  inTheOfficeValue,
  officeOptionLabel,
  telemedicineOptionLabel,
  telemedicineValue,
} from '~/constants/slotsVisitType'
import { removeDiacritics } from '~/utils/Utils'
import findCities from '~/mixins/findCities'

export default {
  name: 'MediWidgetContainer',
  mixins: [
    cmsBlock,
    findCities,
  ],

  data () {
    return {
      isBorderOnFocus: false,
      telemedicineLabel: {
        first: { text: 'Telemedicine', value: telemedicineValue },
        second: { text: 'In the office', value: inTheOfficeValue },
      },

      filter: {
        telemedicine: inTheOfficeValue,
      },

      selectedOption: officeOptionLabel,
      speciality: null,
      city: null,

      cityList: [],
    }
  },

  computed: {
    blockData () {
      return JSON.parse(JSON.stringify(this.blockContent))
    },

    visitsBlock () {
      const visits = this.blockData.tabs.find(tab => tab?.visits)

      if (visits) {
        const { office, telemedicine } = visits;

        [office, telemedicine].forEach(type => type.items.forEach((item) => {
          item.value = item.text.replaceAll(' ', '-')
          item.cities.forEach((city) => {
            city.value = city.text.replaceAll(' ', '-')
          })
        }))
      }

      return visits
    },

    testBlock () {
      return this.blockData.tabs.find(tab => tab?.research)
    },

    specialityList () {
      return this.visitsBlock?.[`${this.selectedOption}`].items
    },
  },

  watch: {
    filter: {
      deep: true,
      handler () {
        this.selectedOption = +this.filter.telemedicine === inTheOfficeValue ? officeOptionLabel : telemedicineOptionLabel
      },
    },

    speciality: {
      deep: true,
      immediate: false,
      async handler () {
        let data

        if (this.speciality) {
          data = await this.findCities(1, removeDiacritics(this.speciality))
        }

        this.cityList = data.cities.map(({ name, slug }) => ({ text: name, value: slug }))
      },
    },
  },

  methods: {
    toggleBorderOnFocus (val) {
      this.isBorderOnFocus = val
    },

    onSearch () {
      const citySlug = this.cityList.find(({ value }) => value === this.city)?.value || ''

      this.$router.push({
        path: this.localePath(removeDiacritics(`/p/${this.speciality}`)),
        query: {
          city: citySlug,
          ...(this.filter.telemedicine === 2 ? { is_telemedicine: 'online' } : {}),
        },
      })
    },
  },
}
