
export default {
  name: 'MediRadioSwitcher',
  props: {
    labelFirstPlace: {
      type: Object,
      required: true,
    },

    labelSecondPlace: {
      type: Object,
      required: true,
    },

    value: {
      type: [String, Boolean, Number],
      default: '',
    },
  },
}
