
import uniqIdMixin from '~/mixins/uniq-id'

export default {
  name: 'MediWidgetSelect',
  mixins: [
    uniqIdMixin,
  ],

  inject: ['modal'],

  props: {
    items: {
      type: Array,
      required: true,
    },

    itemValue: {
      type: String,
      default: 'value',
    },

    placeholder: {
      type: String,
      default: '',
    },

    itemText: {
      type: String,
      default: 'text',
    },

    isBorderOnFocus: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    leftBaseIcon: {
      type: String,
      default: '',
    },

    type: {
      type: String,
      default: '',
    },
  },

  data () {
    return {
      isOpen: false,
      searchText: '',
      selectedValue: this.placeholder || '',
      modalSelectId: null,
    }
  },

  computed: {
    itemsValue () {
      return this.items.map((item) => {
        item.checked = [this.value].includes(item.value)

        return item
      })
    },

    filteredItemsValue () {
      return (this.searchText !== '' || this.searchText !== this.labelInside)
        ? this.items.filter(item => item?.text.toString().toLowerCase().includes(this.searchText.toLowerCase()))
        : this.items
    },

    getModalData () {
      return this.$store.state.modal.modalData
    },
  },

  watch: {
    async getModalData (data) {
      const isValidData = !!data &&
        this.modalSelectId === data.id &&
        !!data.type &&
        data.value !== null

      if (!isValidData) { return }

      await this.onValueChange(data.value)
    },

    isOpen (value) {
      this.$emit('is-border-on-focus', value)

      this.$nextTick(async () => {
        if (!this.$css.breakpoints.mdAndUp && value) {
          try {
            const BaseFormSelectModal = await import('~/components/base/form/select/BaseFormSelectModal.vue')
            const modalInstance = await this.modal()

            this.modalSelectId = this.$id('modal')

            modalInstance.openModal(BaseFormSelectModal.default, {
              labelInside: this.placeholder,
              isSearch: true,
              value: this.selectedValue,
              items: this.items,
              itemValue: this.itemValue,
              isOpen: this.isOpen,
              modalSelectId: this.modalSelectId,
            })
          } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error)
          }
        }
      })
    },
  },

  beforeDestroy () {
    this.$store.dispatch('modal/setModalData', null)
  },

  methods: {
    onSearch () {
      if (!this.isOpen) {
        if (this.disabled) {
          this.isOpen = false
          return
        }

        this.isOpen = true
        this.$nextTick(() => {
          this.$css.breakpoints.mdAndUp
            ? this.$refs.search?.focus() || this.$refs.searchBtn?.focus()
            : this.$refs.modalSearch?.focus()
        })
      }
    },

    onClose (event) {
      event.stopPropagation()

      this.isOpen = false

      this.searchText = ''
      this.selectedValue = this.items.find(el => el.text === this.selectedValue)?.text || this.placeholder
    },

    onValueChange (value) {
      this.isOpen = false
      this.$emit('input', value)

      this.searchText = ''
      this.selectedValue = this.items.find(el => el.value === value)?.text
    },
  },
}
